.main:not(.main--no-overflow) {
    overflow-x: hidden;
    background: #f8f8f8;
}

@media (max-width:575px) {
    .paddingSlider {
        padding-inline: 32px !important;
    }
}

@media(min-width: 576px) {
    .paddingSlider {
        padding-inline: 32px !important;
    }
}

@media(min-width: 768px) {
    .paddingSlider {
        padding-inline: 32px !important;
    }
}

@media(min-width: 992px) {
    .paddingSlider {
        padding-inline: 88px !important;
    }
}

@media(min-width: 1200px) {
    .paddingSlider {
        padding-inline: 90px !important;
    }
}

@media (min-width: 768px) and (orientation: landscape) {
    .heroComponent {
        position: relative;
        overflow: hidden;
        min-height: 37.5rem !important;
    }
}

@media (max-width: 575px) {
    .titleLocation {
        padding-top: 30% !important;
    }
}

@media(min-width: 576px) {
    .titleLocation {
        padding-top: 16% !important;
    }
}

@media(min-width: 768px) {
    .titleLocation {
        padding-top: 22% !important;
    }
}

@media(min-width:920px) {
    .titleLocation {
        padding-top: 15% !important;
    }
}

@media(min-width:1200px) {
    .titleLocation {
        padding-top: 7% !important;
    }
}

@media(min-width:1400px) {
    .titleLocation {
        padding-top: 10% !important;
        padding-bottom: 10% !important;
    }
}

@media(min-width:1600px) {
    .titleLocation {
        padding-top: 7% !important;
        padding-bottom: 10% !important;
    }
}

@media(min-width: 2000px) {
    .titleLocation {
        padding-top: 0% !important;
        padding-bottom: 10% !important;
    }
}

.titleLocation {
    padding-top: 7%;
}

.heroComponent {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100vh;
}

.heroComponent::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
    opacity: .4;
    z-index: 2;
}

.componentDark {
    background-color: #000;
    color: #999;
}

.heroContainer {
    width: 100%;
}

@media (min-width: 768px) and (orientation: landscape) {
    .heroComponent::after {
        position: relative;
    }

    .heroComponent::after {
        content: "";
        display: block;
        height: 0;
        padding-bottom: 56%;
    }
}

.bgWhite {
    background: #FFFFFF;
}

.heroBody {
    position: relative;
    z-index: 3;
}

.subtitleHero {
    color: white;
    font-family: "Daimler CS Regular";
    font-size: 20px;
}

.btnSubmit {
    color: white;
    background: #00ADEF;
    font-family: "Daimler CS Regular";
    font-size: 15px;
    padding: 20px 25px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.btnSubmit:hover {
    color: white;
    background: #0088C6;
}

.buttonChevron {
    width: 0.5625rem;
    height: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 0.125rem;
}

.heroMediaWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.heroMedia {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
}

.divPlayer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

#heroMediaWrapper div {
    height: 100% !important;
}

.vjs-container {
    position: relative;
    height: 100%;
}

.video-js .vjs-default {
    position: relative;
    height: 100%;
}

video {
    object-fit: cover;
}

video .video-js .vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vjs-container div {
    height: 100%;
}

.vpContainer {
    overflow: visible !important;
    padding: 0 48px;
    min-height: 288px;
}