.trailerWrapper {
  gap: 80px;
  padding: 40px;
  width: 100%;
  max-width: 1440px;
}

@media(min-width: 300px) {
  .trailerWrapper {
    gap: 80px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    max-width: 1440px;
    padding-inline: 12px !important;
  }
}

@media(min-width: 576px) {
  .trailerWrapper {
    gap: 80px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    max-width: 1440px;
    padding-inline: 20px !important;
  }
}