.backgroundContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #343a40 // overflow: hidden;
}

.containerHeight {
    height: 100vh !important;
}

.paragraph {
    font-size: 24px;
    font-family: "Daimler CS Regular";
    color: white;
}

.linkError {
    font-size: 24px;
    font-family: "Daimler CS Regular";
}

.btnChevron {
    width: 0.5625rem;
    height: 0.625rem;
    margin-right: 0.625rem;
}

.btnBack {
    color: white;
    font-family: "Daimler CS Regular";
    font-size: 15px;
    padding: 20px 25px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #00ADEF;
}

.btnBack:hover {
    color: white;
    background: #0288bd;
    ;
}