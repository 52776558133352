.videoContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    // overflow: hidden;
}

#content {
    position: relative;
    z-index: 1;
}

.driverName {
    font-family: "Daimler CS Regular";
    font-size: 18px;
    color: #999999;
    text-transform: uppercase;
}

@media(min-width:300px) and (max-width:767.98px) {
    .driverExperience {
        font-family: 44px !important;
    }
}

.driverExperience {
    font-family: "Daimler CAC Regular";
    font-size: 50px;
    color: white;
}

.trailerLabel {
    font-family: "Daimler CS Regular";
    font-size: 18px;
    color: white;
}

.videoPlayer {
    // position: relative;
    min-width: 54vw;
    margin: 0 auto;
    // padding-bottom: 56.25%;
    // overflow: hidden;
}

.videoPlayer video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
}

.visitLabel {
    color: white;
    font-size: 20px;
    font-family: "Daimler CS Regular";
}

.visitLink {
    font-family: "Daimler CS Regular";
    font-size: 20px;
    font-weight: bolder;
    color: #00ADEF;
}

.visitLink:hover {
    color: #026d97;
}

@media(max-width:575px) {
    .visitLabel {
        color: white;
        font-size: 13px;
        padding-top: 24px;
        padding-bottom: 24px;
        font-family: "Daimler CS Regular";
    }

    .visitLink {
        font-family: "Daimler CS Regular";
        font-size: 14px;
        font-weight: bolder;
        color: #00ADEF;
    }

    .imageContainerPadding {
        padding-top: 100px;
        padding-bottom: 150px;
    }

    .videoContainerPadding {
        padding-top: 150px;
        padding-bottom: 150px;
    }
}

@media(min-width: 576px) {
    .visitLabel {
        color: white;
        font-size: 16px;
        font-family: "Daimler CS Regular";
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .visitLink {
        font-family: "Daimler CS Regular";
        font-size: 16px;
        font-weight: bolder;
        color: #00ADEF;
    }

    .imageContainerPadding {
        padding-top: 40px;
        padding-bottom: 0px;
    }

    .videoContainerPadding {
        padding-top: 60px;
        padding-bottom: 25px;
    }
}

@media(min-width: 768px) {
    .visitLabel {
        color: white;
        font-size: 20px;
        font-family: "Daimler CS Regular";
    }

    .visitLink {
        font-family: "Daimler CS Regular";
        font-size: 20px;
        font-weight: bolder;
        color: #00ADEF;
    }

    .imageContainerPadding {
        padding-top: 40px;
        padding-bottom: 0px;
    }
}

@media(min-width: 992px) {
    .visitLabel {
        color: white;
        font-size: 20px;
        font-family: "Daimler CS Regular";
        padding-top: 40px;
        padding-bottom: 0px;
    }

    .imageContainerPadding {
        padding-top: 120px;
        padding-bottom: 90px;
    }

    .videoContainerPadding {
        padding-top: 120px;
        padding-bottom: 90px;
    }
}

@media(min-width: 1200px) {
    .imageContainerPadding {
        padding-top: 100px;
        padding-bottom: 0px;
    }

    .videoContainerPadding {
        padding-top: 75px;
        padding-bottom: 25px;
    }
}

@media(min-width: 1400px) {
    .visitLabel {
        color: white;
        font-size: 20px;
        font-family: "Daimler CS Regular";
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .imageContainerPadding {
        padding-top: 50px;
    }
}

@media(min-width: 1600px) {
    .visitLabel {
        color: white;
        font-size: 20px;
        font-family: "Daimler CS Regular";
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .imageContainerPadding {
        padding-top: 125px;
    }
}