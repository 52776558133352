.background {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@media(max-width: 575px) {
    .background {
        position: fixed;
        top: 0;
        right: 700px !important;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}