.containerFooter {
    margin-right: auto;
    margin-left: auto;
    max-width: 83.33333%;
}

@media(min-width:1024px) {
    .containerFooter {
        max-width: 78.57143%;
    }
}

h2 {
    font-size: 100% !important;
}

p {
    font-family: "Daimler CS Regular";
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
}

a {
    font-family: "Daimler CS Regular";
    color: #999999;
    -webkit-transition: color .3s ease-in-out;
    transition: .3s ease-in-out;
}

a:hover {
    text-decoration: none;
    color: #fff;
}

.siteFooter a {
    -webkit-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
}

a {
    color: currentColor;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.siteFooter {
    font-size: 1rem !important;
    line-height: 1.3125 !important;
    letter-spacing: .0125em !important;
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
    background-color: #1f1f1f;
    color: #999;
    z-index: 10;
}

@media (min-width: 768px) {
    .siteFooter {
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
    }
}

@media(min-width: 1440px) {
    .siteFooter {
        padding-top: 8.75rem !important;
        padding-bottom: 7.5rem !important;
    }
}

footer {
    display: block;
}

@media (min-width: 1440px) {
    .siteFooterGrid {
        display: -ms-grid !important;
        display: grid !important;
        ;
        grid-gap: 5rem 0.75rem !important;
        ;
        -ms-grid-rows: auto 5rem auto !important;
        ;
        -ms-grid-columns: 7fr .75rem 1fr .75rem 3fr .75rem 1fr .75rem 4fr .75rem 1fr .75rem 5fr !important;
        ;
        grid-template:
            "logos . links . contact-blocks . newsletter"
            "copyright . parent-link parent-link . . social" / 7fr 1fr 3fr 1fr 4fr 1fr 5fr !important;
        ;
    }
}

@media (min-width: 768px) {
    .siteFooterGrid {
        display: -ms-grid;
        display: grid;
        grid-gap: 2.5rem 6.25rem;
        -ms-grid-rows: auto 2.5rem auto 2.5rem auto 2.5rem auto;
        -ms-grid-columns: 1fr 6.25rem 1fr;
        grid-template:
            "logos logos"
            "links contact-blocks"
            "newsletter social"
            "copyright parent-link" / 1fr 1fr;
    }
}

@media (min-width: 768px) {
    .siteFooterLogos {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
    }
}

.siteFooterLogos {
    grid-area: logos;
}

.siteFooterLogos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: -1.25rem;
    margin-right: -0.625rem;
    margin-left: -0.625rem;
    -ms-flex-item-align: start;
    align-self: start;
    -ms-grid-row-align: start;
}

.siteFooterLogo {
    margin-top: 1.25rem;
    margin-right: 0.625rem;
    margin-left: 0.625rem;
}

.mb10 {
    margin-bottom: 0.625rem;
}

.fsXsmall {
    font-size: .875rem !important;
    line-height: 1.28571 !important;
    letter-spacing: .01786em !important;
}

@media (min-width: 768px) {
    .siteFooterLinks {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
    }
}

.siteFooterLinks {
    grid-area: links;
}


.h4 {
    font-family: "Daimler CAC Regular";
    color: white;
    font-size: 1.25rem !important;
    line-height: 1.1 !important;
}

.siteFooterHeading {
    font-family: "Daimler CAC Regular";
    color: #fff;
    margin-bottom: 1.875rem;
}

@media(min-width: 1440px) {
    .siteFooterHeading {
        margin-bottom: 3.125rem !important;
    }
}

@media (min-width: 768px) {
    .h4 {
        font-size: 1.5rem !important;
        line-height: 1.08333 !important;
    }
}

.link {
    font-size: 16px !important;
}

.listItem:not(:first-child) {
    margin-top: 1.25rem;
}

@media (min-width: 1440px) {
    .footerLinks {
        display: flex !important;
        flex-direction: column !important;
    }
}

.footerLinks {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1.25rem 1fr;
    grid-template-columns: 1fr 1fr;
}

.unstyledList {
    list-style: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.siteFooter a {
    -webkit-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
}

a {
    color: currentColor;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

@media (min-width: 768px) {
    .siteFooterContactBlocks {
        -ms-grid-row: 3;
        -ms-grid-column: 3;
        grid-template-columns: repeat(auto-fit, minmax(8.75rem, 1fr));
    }
}

@media (min-width: 1440px) {
    .siteFooterContactBlocks {
        display: flex !important;
        flex-direction: column !important;
        margin-bottom: 1.25rem;
    }

    .contactBlockHeading {
        margin-bottom: 1.25rem;
    }
}

.siteFooterContactBlocks {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1.25rem 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem 1.25rem;
    grid-area: contact-blocks;
}

.contactBlock {
    display: block;
}

.contactBlockHeading {
    margin-bottom: 1.75rem;
}

@media (min-width: 768px) {
    .siteFooterSocial {
        -ms-grid-row: 5;
        -ms-grid-column: 3;
    }
}

.siteFooterSocial {
    grid-area: social;
}

.siteFooterSocial {
    color: #666;
}

.audioOnly {
    position: absolute;
    overflow: hidden;
    padding: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    clip: rect(0, 0, 0, 0);
}

.socialLinks {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;

}

.socialLinkItem {
    display: list-item;
    text-align: -webkit-match-parent;
}

.socialLinkLink {
    color: currentColor;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

@media (min-width: 768px) {
    .siteFooterParentLink {
        -ms-grid-row: 7;
        -ms-grid-column: 3;
    }
}

.siteFooterCopyright {
    grid-area: copyright;
}

@media (min-width: 768px) {

    .siteFooterParentLink,
    .siteFooterCopyright {
        margin-top: 2.5rem;
    }
}

@media (min-width: 768px) {
    .siteFooterParentLink {
        -ms-grid-row-align: center;
        align-self: center;
    }
}

.siteFooterParentLink {
    grid-area: parent-link;
}

.siteFooterEnd {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@media (max-width: 767px) {
    .siteFooterNewsLetter {
        margin-top: 5rem;
    }
}

.siteFooterNewsLetter {
    font-size: 1.125rem !important;
    line-height: 1.33333 !important;
    letter-spacing: .01389em !important;
    grid-area: newsletter !important;
}

.siteFooterNewsletterLink {
    width: 100%;
    margin-left: auto;
}

@media (max-width: 767px) {
    .siteFooterLinks {
        margin-top: 50px;
    }

    .siteFooterContactBlocks {
        margin-top: 50px;
    }

    .siteFooterSocial {
        margin-top: 50px;
    }

    .siteFooterCopyright {
        margin-top: 50px;
    }
}