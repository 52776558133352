.loaderContainer {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1;
}

.loadingLabel {
    font-family: "Daimler CAC Regular";
    font-size: 40px;
}


//SECONDO ESEMPIO
$Loader-color: #fff;
$Loader-size: 13rem;
$Loader-offset: 1rem;
$Loader-timing: ease-in-out;

.spinner {
    position: absolute;
    bottom: -53%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 124px;
    margin-top: $Loader-size/2;
    margin-bottom: $Loader-size/2;

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        animation-duration: 1.8s;
        animation-iteration-count: infinite;
        animation-timing-function: $Loader-timing;
        filter: drop-shadow(0 0 $Loader-offset/2.25 rgba($Loader-color, 0.75));
    }

    &:before {
        width: 100%;
        padding-bottom: 100%;
        box-shadow: inset 0 0 0 $Loader-offset $Loader-color;
        animation-name: pulsA;
    }

    &:after {
        width: calc(100% - #{$Loader-offset}*2);
        padding-bottom: calc(100% - #{$Loader-offset}*2);
        box-shadow: 0 0 0 0 $Loader-color;
        animation-name: pulsB;
    }
}

@keyframes pulsA {
    0% {
        box-shadow: inset 0 0 0 $Loader-offset $Loader-color;
        opacity: 1;
    }

    50%,
    100% {
        box-shadow: inset 0 0 0 0 $Loader-color;
        opacity: 0;
    }
}

@keyframes pulsB {

    0%,
    50% {
        box-shadow: 0 0 0 0 $Loader-color;
        opacity: 0;
    }

    100% {
        box-shadow: 0 0 0 $Loader-offset $Loader-color;
        opacity: 1;
    }
}

.spinnerCustom {
    width: 20px;
    height: 20px;
}


//PRIMO ESEMPIO
// .spinner {
//     position: absolute;
//     width: 64px;
//     height: 64px;
//     border: 5px solid;
//     border-color: #666666 transparent #666666 transparent;
//     border-radius: 50%;
//     animation: spin-anim 1.2s linear infinite;
// }

// @keyframes spin-anim {
//     0% {
//         transform: rotate(0deg);
//     }

//     100% {
//         transform: rotate(360deg);
//     }
// }