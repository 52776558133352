form {
    margin-top: 30px;
}

.passwordFormTitle {
    font-family: "Daimler CAC Regular";
    font-size: 24px;
    color: #FFF;
}

.inputPassword {
    background-color: transparent;
    border-bottom: 1px solid #666;
    max-width: 600px;
    height: 68px;
    color: #999;
    font-size: 18px;
}

.inputPassword:focus {
    background-color: transparent;
}

@media(max-width: 575px) {
    .boxDescription {
        font-size: 14px !important;
        padding: 20px !important;
        max-width: 362px !important;
        font-family: "Daimler CS Regular";
        font-size: 15px;
        letter-spacing: -0.075px;
        color: #FFF;
        text-align: justify;
    }

    .passwordFormTitle {
        font-size: 21px;
        padding-left: 22px;
        padding-right: 22px;
    }

    .inputPassword {
        font-size: 18px;
        max-width: 352px;
    }
}

@media(min-width:576px) {
    .boxDescription {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        max-width: 500px !important;
        font-family: "Daimler CS Regular";
        font-size: 15px;
        letter-spacing: -0.075px;
        color: #FFF;
    }
}

@media(min-width: 768px) {
    .boxDescription {
        padding-top: 30px;
        padding-bottom: 30px;
        max-width: 614px !important;
        font-family: "Daimler CS Regular";
        font-size: 15px;
        letter-spacing: -0.075px;
        color: #FFF;
    }
}

.boxError {
    font-family: "Daimler CS Regular";
    font-size: 18px;
    font-weight: bolder;
    color: #00ADEF;
}


.btnSubmit {
    color: #333;
    font-family: "Daimler CS Regular";
    font-size: 15px;
    padding: 20px 25px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #666;
}

.buttonChevron {
    width: 0.5625rem;
    height: 0.625rem;
    margin-right: 0.625rem;
}

.active {
    color: white;
    background: #00ADEF;
}

.active:hover {
    color: white;
    background: #0088C6 !important;
}