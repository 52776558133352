@media(max-width:575px) {
    .containerImage {
        max-height: 340px !important;
        height: 170px !important;
    }

    .imageTextContainer {
        width: 325px;
        height: 64px;
        position: absolute;
        top: 12px !important;
        left: 12px !important;
        bottom: 190px;
        right: 315px;
    }

    .imageText1 {
        color: var(--Generic, #FFF);
        font-family: "Daimler CAC Regular";
        font-size: 24px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .imageText2 {
        color: var(--Generic, #FFF);
        font-family: "Daimler CAC Regular";
        font-size: 19px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
    }
}

@media(min-width: 576px) {
    .containerImage {
        max-height: 340px !important;
        height: 240px !important;
    }
}

@media(min-width: 768px) {
    .containerImage {
        max-height: 340px !important;
        height: 154px !important;
    }
}

@media(min-width: 992px) {
    .containerImage {
        max-height: 340px !important;
        height: 220px !important;
    }
}

@media(min-width: 1200px) {
    .containerImage {
        max-height: 340px !important;
        height: 320px !important;
    }
}

@media(min-width: 1400px) {
    .containerImage {
        max-height: 400px !important;
        height: 360px !important;
    }
}

@media(min-width: 1600px) {
    .containerImage {
        max-height: 500px !important;
        height: 420px !important;
    }
}


.containerImage {
    position: relative;
    max-height: 340px;
    height: 320px;
    object-fit: cover;
}

.containerVideo {
    position: relative;

}

.overflowHover {
    top: 0;
    width: 100%;
    position: absolute;
    height: 100%;
}


.imgHovered {
    transform: scale(1.2);
    filter: brightness(1.2);
    transition: transform 0.3s, filter 0.3s;
}

.imgNotHovered {
    transform: scale(1);
    filter: brightness(0.8);
    transition: transform 0.3s, filter 0.3s;
}

.playerButtons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25%;
}

.fullScreenBtnGroup {
    position: absolute;
    top: 0;
    left: 0;
}

.fullScreenButton {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: rgba($color: #000000, $alpha: 0.5);
    padding: 17px 15px;
    height: 40px;
}

.button {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--Primary, #00ADEF);
    padding: 17px 25px;
    height: 50px;
}

.button:hover {
    background: #0088C6 !important;
}

.playButton {
    min-width: 62px;
}

.downloadButton {
    position: absolute;
    bottom: 0;
    left: 0;
}

.downloadImageButton {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 55px;
    padding: 20px 25px;
}

.downClass {
    font-size: 15px;
    font-family: "Daimler CS Regular";
}

.socialIcons {
    position: absolute;
    top: 0;
    right: 0;
}

.imageTextContainer {
    width: 325px;
    height: 64px;
    position: absolute;
    top: 28px;
    left: 28px;
    bottom: 190px;
    right: 315px;
}

.imageText1 {
    color: var(--Generic, #FFF);
    font-family: "Daimler CAC Regular";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.imageText2 {
    color: var(--Generic, #FFF);
    font-family: "Daimler CAC Regular";
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
}

.socialIcon {
    width: 30px !important;
    height: 30px !important;
    background: black;
}

.customControlBar {
    margin-bottom: 2.5rem !important;
}