@media (max-width: 575px) {
    h1 {
        font-size: 45px !important;
    }
}

@media(max-width:767px) {
    .mainLoginTitleBox {
        padding-top: 10px !important;
        padding-bottom: 15px !important;
    }
}

.loginTitle {
    font-size: 80px !important;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.mainLoginTitleBox {
    padding-top: 30px;
    padding-bottom: 30px;
}


.mainTitleBox {
    padding-top: 30px;
    padding-bottom: 30px;
}

.loginTitle::before {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    margin-bottom: 30px;
    background-color: #D9D9D9;
    position: relative;
    top: 0;
    left: 46%;
}

@media(max-width: 575px) {
    .homeTitle {
        font-size: 40px !important;
    }

    .loginTitle {
        font-size: 45px !important;
    }

    .loginTitle::before {
        left: 42% !important;
    }

    .homeTitle::before {
        content: "";
        display: block;
        width: 32px !important;
        height: 3px;
        margin-bottom: 10px !important;
        background-color: #D9D9D9;
        position: relative;
        top: 0;
    }

}

@media(min-width: 576px) {
    .loginTitle {
        font-size: 67px !important;
    }

    .homeTitle {
        font-size: 60px !important;
    }

    .homeTitle::before {
        content: "";
        display: block;
        width: 48px !important;
        height: 3px;
        margin-bottom: 25px !important;
        background-color: #D9D9D9;
        position: relative;
        top: 0;
    }
}

@media(min-width:768px) {
    .loginTitle {
        font-size: 80px !important;
    }

    .homeTitle {
        font-size: 66px !important;
    }

    .homeTitle::before {
        content: "";
        display: block;
        width: 44px !important;
        height: 3px;
        margin-bottom: 25px !important;
        background-color: #D9D9D9;
        position: relative;
        top: 0;
    }
}

@media(min-width: 992px) {
    .homeTitle {
        font-size: 92px !important;
    }

    .homeTitle::before {
        content: "";
        display: block;
        width: 66px !important;
        height: 3px !important;
        margin-bottom: 30px !important;
        background-color: #D9D9D9;
        position: relative;
        top: 0;
    }

}

@media(min-width: 1200px) {
    .homeTitle {
        font-size: 100px !important;
    }

    .loginTitle {
        font-size: 80px !important;
    }

    .homeTitle::before {
        content: "";
        display: block;
        width: 77px !important;
        height: 3px !important;
        margin-bottom: 30px !important;
        background-color: #D9D9D9;
        position: relative;
        top: 0;
    }
}

.homeTitle::before {
    content: "";
    display: block;
    width: 60px;
    height: 3px;
    margin-bottom: 30px;
    background-color: #D9D9D9;
    position: relative;
    top: 0;
}