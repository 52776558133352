.buttonLeft {
    position: absolute;
    left: -28px;
    top: calc(50% - 28px);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 100%;
    background: var(--Primary, #00ADEF);
    padding: 15px 20px;
    height: 56px;
    z-index: 1;
}