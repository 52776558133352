.customBgDark {
    background-color: #080808b3;
    transition: cubic-bezier(0.25, 0.1, 0.25, 0.1) 0.5s;
}

.bgTransparent {
    transition: cubic-bezier(0.25, 0.1, 0.25, 0.1) 0.3s;
}

.navbar-container {
    z-index: 2;
    position: fixed;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
}

nav {
    position: fixed !important;
    width: 100% !important;
    z-index: 11 !important;
}

@media(max-width: 575px) {
    .imgSize {
        width: 80px !important;
        height: 80px !important;
    }
}

@media(min-width: 576px) {
    .imgSize {
        width: 100px !important;
        height: 100px !important;
    }
}

@media(min-width: 768px) {
    .imgSize {
        width: 110px !important;
        height: 110px !important;
    }
}

@media(min-width: 992px) {
    .imgSize {
        width: 128px !important;
        height: 128px !important;
    }
}

.imgSize {
    width: 128px;
    height: 128px;
}