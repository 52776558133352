.trailerText {
  width: 100%;
  padding-inline: 20px;
  padding-block: 10px;
}

h4 {
  font-family: "Daimler CAC Regular";
  font-size: 30px;
  font-weight: normal;
  height: auto;
}

.trailerRect {
  width: 40px;
  height: 3px;
  background-color: black;
  margin-bottom: 15px;
}

.titleText {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bodyText {
  font-family: "Daimler CS Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
}