.swiper {
    margin: 0 !important;
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    margin: 0 !important;
    height: 300px;
}

.marginLeftArrow {
    margin-left: 2rem;
}

.marginRightArrow {
    margin-right: 2rem;
}

.slider {
    overflow: visible !important;
}

.button {
    margin-top: 50px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 100%;
    background: var(--Primary, #00ADEF);
    padding: 15px 20px;
    height: 56px;
}

.button:hover {
    background: #0088C6 !important;
}

.swiper-slide,
.swiper-slide-next {
    width: auto !important;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
    display: none;
}